"use strict";

import axios from "axios";
var baseControllerAddress = 'firmware/';

export default {
    getLocale: (id) => {
        return axios.post('/api/' + 'locale/GetById/', id)
            .then(result => {
                return result;
            }).catch(function (error) {
                alert(error);
            });
    },
    getFirmwareList: (action, vm) => {
        return axios.post('/api/' + baseControllerAddress + action, vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    updateFirmware: (vm) => {
        return axios.put('/api/' + baseControllerAddress + "update", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                //alert(error);
            });
    },
    createFirmware: (vm) => {
        //console.log('Creating firmware', vm)
        return axios.post('/api/' + baseControllerAddress + "create", vm)
            .catch(function (error) {
                //alert(error);
            });
    },
    getFirmware: (id) => {
        return axios.get('/api/' + baseControllerAddress + "get/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                //alert(error);
            });
    },
    getSemanticVersions: () => {
        return axios.get('/api/' + baseControllerAddress + "getAllSemanticVersions/")
            .then(result => {
                return result.data;
            }).catch(function (error) {
                //alert(error);
            });
    },
    getMqttMessageApiVersions: () => {
        return axios.get('/api/' + baseControllerAddress + "getMqttMessageApiVersions/")
            .then(result => {
                return result.data;
            }).catch(function (error) {
                //alert(error);
            });
    },
    deleteFirmware: (id) => {
        return axios.delete('/api/' + baseControllerAddress + "deleteFirmware/" + id)
            .catch(function (error) {
                //alert(error);
            });
    },
}