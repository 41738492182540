<template>
    <div>
        <dmx-title :bc-items="bcItems">{{ $t('admin_dashboard_firmware_title') }}</dmx-title>
        <b-row align-v="center" class="mb-1">
            <b-col>
                {{ pagination.totalListCount }} found
                <span class="ml-3">
                    show: <b-link @click="setPerPage(10)" :style="((options.perPage == 10) ? 'font-weight: bold;' : ' ')">10</b-link> |
                    <b-link @click="setPerPage(20)" :style="((options.perPage == 20) ? 'font-weight: bold;' : ' ')">20</b-link> |
                    <b-link @click="setPerPage(100)" :style="((options.perPage == 100) ? 'font-weight: bold;' : ' ')">100</b-link>
                </span>
            </b-col>
            <b-col>
                <b-input v-model="searchField"
                         @input="searchWithText()"
                         :loading="searchFieldIsLoading"
                         :placeholder="$t('common_search')">
                </b-input>
            </b-col>
            <b-col align="right">
                <b-button variant="success" @click="newFirmware()">
                    <b-icon icon="plus"></b-icon>
                    {{ $t('common_create') }}
                </b-button>
            </b-col>
        </b-row>

        <b-table id="firmware-table"
                 thead-class="dmx-thead-block"
                 :busy="loading"
                 outlined
                 striped
                 bordered
                 hover
                 :items="firmwareList"
                 :fields="headers"
                 @row-clicked="editFirmware">
        </b-table>

        <div>
            <b-pagination align="center" v-model="options.currentPage"
                          :total-rows="pagination.totalListCount"
                          :per-page="options.perPage"
                          aria-controls="firmware-table">
            </b-pagination>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
    import firmwareService from '@/services/firmwareService';
    import router from '@/router';
    import moment from 'moment';

    export default {
        name: "FirmwareList",
        data: () => ({
            search: '',
            loading: true,
            options: {
                perPage: 10,
                currentPage: 1
            },

            //flags
            searchFieldIsLoading: false,

            // values
            searchField: '',
            firmwareList: [],

            // pagination
            pagination: {
                totalListCount: 0
            },
            filterTableHeadName: '',
            timeoutId: 0,
            bcItems: [
                {
                    text: 'firmware',
                    href: '/firmware',
                    active: true,
                }
            ]
        }),
        computed: {
            headers() {
                return [
                    {
                        text: this.$t('common_id'),
                        align: 'start',
                        sortable: true,
                        value: 'id',
                    },
                    {
                        text: this.$t('common_name'),
                        sortable: true,
                        value: 'name',
                    },
                    {
                        text: this.$t('common_semver'),
                        sortable: true,
                        value: 'semanticVersion',
                    },
                    {
                        text: this.$t('common_mqttapi'),
                        sortable: true,
                        value: 'mqttMessageApiVersion',
                    },
                    {
                        text: this.$t('common_size'),
                        sortable: false,
                        value: 'fileSize',
                    },
                    {
                        text: this.$t('common_created'),
                        sortable: true,
                        value: 'createdOnUtc',
                    }
                ]
            }
        },
        watch: {
            options: {
                handler() {
                    this.getFirmware()
                },
                deep: true,
            },
        },
        methods: {
            newFirmware() {
                router.push({ name: 'editFirmware', params: { firmwareId: 0 } })
            },
            setPerPage(num) {
                this.options.currentPage = 1;
                this.options.perPage = num;
            },
            editFirmware(e) {
                router.push({ name: 'editFirmware', params: { firmwareId: parseInt(e.id) } })
            },
            searchWithText() {
                this.searchFieldIsLoading = true;

                if (this.timeoutId > 0) {
                    clearTimeout(this.timeoutId);
                }
                this.timeoutId = window.setTimeout(() => {
                    this.getFirmware();
                }, 1000);
            },

            filterByTableHeader(tableHeaderName) {
                this.filterTableHeadName = tableHeaderName;
                this.getFirmware();
            },
            getFirmware() {
                firmwareService.getFirmwareList("FilterFirmwareList", {
                    Count: this.options.perPage,
                    //Firmware: [],
                    //SortBy: this.options.sortBy[0],
                    SearchTerm: this.searchField,
                    //SortDesc: this.options.sortDesc[0],
                    Page: this.options.currentPage
                })
                    .then((data) => {
                        data.items.map(x => {
                            x.createdOnUtc = moment(x.createdOnUtc).format('YYYY-MM-DD HH:mm:ss');
                        });
                        this.firmwareList = data.items;
                        this.searchFieldIsLoading = false;
                        this.options.currentPage = data.page
                        this.pagination.totalListCount = data.totalListCount

                        this.loading = false
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            }
        },
        mounted() {
            this.getFirmware();
        }
    }
</script>
